<template>
    <div class="w-full flex flex-col bg-gray-50 h-full relative">

        <autralis-success-comp :show="savedFlag"/>
        <autralis-error-comp :show="error" :text="error"/>

        <autralis-modal-comp :modal="modal"/>

        <autralis-loading-table-comp v-if="fetchingBusinessUnits.loading" :columns="columns"/>
        <!--HEADER-->
        <div v-else class="flex flex-col w-full">
            <div class="bg-gray-50 md:bg-white flex w-full p-6 sticky z-10" :style="'top:' + headerTop + 'px'">
                <div class="flex flex-1 w-full">
                    <!-- SMALL SCREENS (<1245px) -->
                    <div class="business-units-header-sm flex flex-col w-full">
                        <div class="flex justify-between w-full">
                            <!-- BUTTON ADD NEW -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span>Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex items-center justify-center relative">
                                <i class="fal fa-ellipsis-h-alt text-2xl text-gray-500 hover:text-blue-700 transition ease-in-out duration-150 cursor-pointer" @click="menuOpen = !menuOpen"/>
                                <div v-if="menuOpen" class="bg-white absolute right-0 z-10 rounded-md shadow-lg p-4 dropdown-nav" style="">
                                    <div v-if="businessUnits && businessUnits.length > 0" class="flex items-center mb-4 cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>


                                    <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 mt-4">
                                        <autralis-delete-business-unit-comp :business-unit-ids="businessUnitIds"
                                                                            :company-id="$props.companyId"
                                                                            :page="page"
                                                                            :itemsPerPage="itemsPerPage"
                                                                            @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>


                    <!-- NORMAL SCREENS (>=1245px and <1450) -->
                    <div class="business-units-header-base flex flex-col w-full">
                        <div class="flex w-full">
                            <!-- BUTTON ADD NEW -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-1"/><span>Add</span>
                                </button>
                            </div>

                            <!-- OTHER BUTTONS DROPDOWN -->
                            <div class="flex">
                                <div class="flex items-center">
                                    <div v-if="businessUnits && businessUnits.length > 0" class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>
                                </div>


                                <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 pl-4 ml-4">
                                    <autralis-delete-business-unit-comp :business-unit-ids="businessUnitIds"
                                                                        :company-id="$props.companyId"
                                                                        :page="page"
                                                                        :itemsPerPage="itemsPerPage"
                                                                        @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                </div>
                            </div>
                        </div>

                        <!-- FILTERS -->
                        <div class="flex mt-4">
                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="false" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer font-medium rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>


                    <!-- BIG SCREENS (>=1450px) -->

                    <div class="business-units-header-lg flex justify-between w-full">
                        <div class="flex flex-1">
                            <!-- ADD NEW BUTTON -->
                            <div class="border-r border-gray-200 pr-4 mr-4">
                                <button @click="addNew"
                                        class="primary-button-dark flex items-center justify-center"
                                        style="height: 38px">
                                    <i class="fal fa-plus mr-0 md:mr-1"/>
                                    <span class="hidden md:block">Add</span>
                                </button>
                            </div>
                            <!-- OTHER BUTTONS -->
                            <div class="flex">
                                <div class="flex items-center">
                                    <div v-if="businessUnits && businessUnits.length > 0" class="flex cursor-pointer text-gray-500 hover:text-gray-800">
                                        <vue-excel-xlsx :data="convertToXlsxData.data"
                                                        :columns="convertToXlsxData.columns"
                                                        :filename="convertToXlsxData.fileName"
                                                        :sheetname="convertToXlsxData.sheetName">
                                            <i class="fal fa-download mr-2"/>Download Excel
                                        </vue-excel-xlsx>
                                    </div>
                                </div>


                                <div v-if="selected.length > 0" class="flex items-center flex-shrink-0 border-l border-gray-200 pl-4 ml-4">
                                    <autralis-delete-business-unit-comp :business-unit-ids="businessUnitIds"
                                                                        :company-id="$props.companyId"
                                                                        :page="page"
                                                                        :itemsPerPage="itemsPerPage"
                                                                        @on-success="selected = [];selectAll = false;savedFlag=true"/>
                                </div>
                            </div>
                        </div>


                        <!-- FILTERS -->
                        <div class="flex">

                            <autralis-combo-button-comp :title="sort === null ? 'Sort' : sort.title" :right="true" :items="selectColumns()" @select-item="selectSort" :z="10"/>
                            <div @click="setSortType"
                                 class="inline-flex items-center ml-3 px-2 py-1 border border-gray-300 text-sm leading-5 cursor-pointer rounded shadow-sm px-4 py-2 bg-white text-gray-700 hover:bg-gray-50 focus:outline-none">
                                <div v-if="sort && sort.type === 'desc'"><i class="fas fa-sort-amount-down"/></div>
                                <div v-else><i class="fas fa-sort-amount-up"/></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg-gray-50 md:bg-white flex flex-1 overflow-y-auto mb-6">
                <div class="align-middle inline-block min-w-full overflow-hidden px-6">

                    <!--                <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative" :style="{height: tableHeight  + 'px'}">-->
                    <div class="z-0 overflow-x-auto bg-white rounded-lg overflow-y-auto relative border border-gray-200">
                        <table class="border-collapse table-auto w-full whitespace-no-wrap bg-white table-striped relative">
                            <thead>
                            <tr class="text-left">
                                <th class="py-2 px-3 sticky top-0 border-b border-gray-200 bg-white" style="width: 20px">
                                    <label class="text-teal-500 inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox" class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded" v-model="selectAll">
                                    </label>
                                </th>
                                <th class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">Edit</th>
                                <th v-for="(col, index) in columns"
                                    :key="'head-' + col.id + '-' + index"
                                    :style="{minWidth: col.width + 'px' }"
                                    class="bg-white sticky top-0 border-b border-gray-200 px-2 py-2 text-gray-600 font-bold tracking-wider uppercase text-xs">{{ col.title }}
                                </th>
                            </tr>
                            </thead>

                            <!-- LOADING -->
                            <tbody v-if="fetchingBusinessUnits.loading">
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div class="flex flex-row justify-center items-center p-2 font-bold text-2xl text-gray-400 my-8">
                                        <div><span><span class="fas fa-spinner-third fa-spin"></span></span></div>
                                        <div class="ml-4">Loading</div>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                            <!-- ERROR -->
                            <tbody v-else-if="!fetchingBusinessUnits.loading && fetchingBusinessUnits.error">
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div class="flex rounded-md bg-red-50 p-4 items-center m-6">
                                        <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                                        <h3 class="text-sm font-medium text-red-800">{{ fetchingBusinessUnits.error }}</h3>
                                    </div>
                                </td>
                            </tr>
                            </tbody>

                            <!-- NO RESULTS -->
                            <tbody v-else-if="!fetchingBusinessUnits.loading && !fetchingBusinessUnits.error && businessUnits.length < 1">
                            <tr>
                                <td :colspan="Object.keys(columns).length + 2">
                                    <div class="flex flex-row justify-center items-center p-2 font-bold text-2xl">No records found</div>
                                </td>
                            </tr>
                            </tbody>

                            <!-- RESULTS -->
                            <tbody v-else>
                            <tr v-for="(item, index) in businessUnits"
                                :key="item.id + '-' + index"
                                :class="[
                                'text-gray-500 hover:bg-blue-500 hover:text-white cursor-pointer',
                                 selected.includes(item.id) && 'bg-blue-50',
                                 currentlyActive === item.id && 'bg-blue-500 text-white'
                                 ]">
                                <td class="border-dashed border-t border-gray-200 px-3" style="width: 20px">
                                    <label class="inline-flex justify-between items-center hover:bg-gray-200 px-2 py-2 rounded-lg cursor-pointer">
                                        <input type="checkbox"
                                               class="focus:ring-blue-500 h-4 w-4 text-blue-600 border-gray-300 rounded"
                                               :checked="selected.includes(item.id)"
                                               @click.stop="handleSelectCheckbox(item.id)">
                                    </label>
                                </td>
                                <td class="border-dashed border-t border-gray-200 px-3 cursor-pointer underline"
                                    style="min-width: 60px"
                                    @click="edit(item)">
                                    Edit
                                </td>

                                <td v-for="(col, index) in columns"
                                    :key="col.id + '-' + index"
                                    :style="{minWidth: col.width + 'px' }"
                                    class="border-dashed border-t border-gray-200 select-none"
                                    @click.stop="handleClick(item, $event)">
                                    <component :is="col.component" :column="col.id" :subColumn="col.subColumn" :item="item" @select-item="select"/>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>


                </div>
            </div>

            <div v-if="!fetchingBusinessUnits.loading && $store.getters['master/totalBusinessUnits'] > itemsPerPage"
                 class="px-6 py-2 w-full h-14 static bottom-0 bg-gray-50 text-gray-800 left-0 right-0">
                <autralis-paginator-comp
                    :currentPage="page"
                    :recordsPerPage="itemsPerPage"
                    :totalCount="$store.getters['master/totalBusinessUnits']"
                    @on-change-page="onPageChange"
                />
            </div>
        </div>
    </div>

</template>

<script>
import ErrorBox from "@/components/error-box";
import SuccessBox from "@/components/success-box";
import comboButton from '@/components/combo-button'
import BaseCol from '../../columns/base-column'
import BaseColCountry from '../../columns/base-column-country'
import BaseColCheck from '../../columns/base-column-check'
import BaseColState from '../../columns/base-column-state'
import BaseColMultipleItems from '../../columns/base-column-multiple-items'
import Paginator from './components/Paginator'
import Modal from "@/components/modal";
import {modalInfo} from "@/common/modal";

import {every, filter, find, isBoolean, isEmpty, isInteger, isObject, map, orderBy, sortBy} from "lodash";
import {handleFetchBusinessUnits} from "./handlers";
import {DETAIL_PANEL_KEYS, HOME_LIST_ITEMS_PER_PAGE} from "../../../constants";
import DeleteBusinessUnit from "@/master/views/Home/company/components/DeleteBusinessUnit";
import LoadingTable from "@/components/loading-table";

const defaultColumns = {
    name: {width: 150, id: 'name', title: 'Name', component: 'autralis-base-col-comp', defaultSort: true},
    vatNumber: {width: 150, id: 'vatNumber', title: 'VAT Number', component: 'autralis-base-col-comp', defaultSort: false},
    address: {width: 240, id: 'address', subColumn: 'address', title: 'Address', component: 'autralis-base-col-comp', defaultSort: false},
    postal: {width: 100, id: 'address', subColumn: 'postalCode', title: 'Postal', component: 'autralis-base-col-comp', defaultSort: false},
    city: {width: 150, id: 'address', subColumn: 'city', title: 'City', component: 'autralis-base-col-comp', defaultSort: false},
    telephone: {width: 150, id: 'telephone', title: 'Telephone', component: 'autralis-base-col-comp', defaultSort: false},
    email: {width: 150, id: 'email', title: 'Email', component: 'autralis-base-col-comp', defaultSort: false},
    groups: {width: 240, id: 'groups', title: 'Groups', component: 'autralis-base-col-multiple-items-comp', defaultSort: false},
};

export default {
    name: "BusinessUnits",
    props: {
        companyId: {type: Number}
    },
    data() {
        return {
            columns: defaultColumns,
            views: [
                {
                    id: 'general',
                    title: 'General',
                    columns: defaultColumns
                }
            ],
            savedFlag: null,
            sortItem: null,
            selectAll: false,
            sortFlag: false,
            tableHeight: window.innerHeight - 206,
            selectedId: null,
            selected: [],
            fetchingBusinessUnits: {
                loading: false,
                error: null
            },
            error: null,
            sendInvitation: {
                saving: false
            },
            sort: null,
            //Pagination
            page: 1,
            itemsPerPage: HOME_LIST_ITEMS_PER_PAGE,
            totalCount: null,
            deleting: false,
            modal: modalInfo(),
            stateTriggers: [],
            numClicks: 0,
            clickTimer: null,
            currentlyActive: null,
            menuOpen: false,
            headerTop: 0
        }
    },

    watch: {
        selectAll(val) {
            this.selected = val ? this.businessUnits.map(i => i.id) : []
        },
        sort() {
            this.updateBusinessUnits(this.$store.getters['master/businessUnits'])
        },

        '$store.state.master.detail': function () {
            const storeDetail = this.$store.state.master.detail;
            if (storeDetail) {
                if (storeDetail['key'] && storeDetail['key'] === DETAIL_PANEL_KEYS.EDIT_BUSINESS_UNIT) {
                    this.currentlyActive = storeDetail['id']
                }
            } else {
                this.currentlyActive = null
            }
        },
        companyId(newCompanyId) {
            this.fetchBusinessUnits(newCompanyId);
        }
    },

    computed: {
        businessUnits() {
            return this.sortedItems(this.$store.getters['master/businessUnits']);
        },

        businessUnitIds() {
            const selectedBusinessUnits = filter(this.businessUnits, bu => this.selected.includes(bu.id))
            return map(selectedBusinessUnits, bu => bu.id)
        },

        convertToXlsxData() {
            let fileName = 'business-units';

            const sheetName = 'users'
            const columns = [
                {label: "Name", field: "name"},
                {label: "Vat number", field: "vatNumber"},
                {label: "Address", field: "address"},
                {label: "Postal code", field: "postal"},
                {label: "City", field: "city"},
                {label: "Telephone", field: "telephone"},
                {label: "Email", field: "email"},
                {label: "Groups", field: "groups"},
            ]

            const ordered = orderBy(this.$store.getters['master/businessUnits'], ['name'], ['asc'])
            const mappedData = map(ordered, bu => {
                return {
                    'name': bu.name,
                    'vatNumber': bu.vatNumber,
                    'address': bu.address ? bu.address.address : '',
                    'postal': bu.address ? bu.address.postalCode : '',
                    'city': bu.address ? bu.address.city : '',
                    'telephone': bu.telephone,
                    'email': bu.email,
                    'groups': (map(bu.groups, gr => gr.name)).join(', '),
                }
            })

            return {
                columns: columns,
                data: mappedData,
                fileName,
                sheetName
            }
        }
    },

    methods: {
        onResize() {
            if (window.innerWidth < 1024) {
                this.headerTop = 157;
            } else {
                this.headerTop = 0
            }
        },
        setInitSort() {
            const item = find(this.selectColumns(), col => col.defaultSort);
            if (item) this.selectSort(item);
        },

        sortedItems(items) {
            let results = items;

            if (!this.sort) return results;

            if (isBoolean(items[this.sort.field]) || isInteger(items[this.sort.field])) {
                results = sortBy(items, [this.sort.field])
            } else if (every(items, item => isObject(item[this.sort.field]))) {
                results = orderBy(items, [item => item[this.sort.field][this.sort.subField]], [this.sort.type])
            } else {
                results = orderBy(items, [this.sort.field], [this.sort.type])
            }
            return results;
        },

        selectColumns() {
            return Object.keys(this.columns).map(c => {
                return {
                    item: c,
                    name: this.columns[c].title,
                    key: this.columns[c].id,
                    subKey: this.columns[c].subColumn,
                    counter: null,
                    icon: null,
                    separator: false,
                    defaultSort: this.columns[c].defaultSort
                }
            });
        },

        handleSelectCheckbox(id) {
            let newSelected = null;
            if (find(this.selected, item => item === id)) {
                newSelected = filter(this.selected, item => item !== id);
            } else {
                newSelected = [...this.selected, id]
            }
            this.selected = newSelected
        },

        edit(item) {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_BUSINESS_UNIT,
                companyId: this.$props.companyId,
                businessUnitId: item.id,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        singleRowClick(item) {
            this.handleSelectCheckbox(item.id)
        },
        doubleRowClick(item) {
            this.edit(item);
        },

        handleClick(item, e) {
            e.preventDefault()
            this.numClicks++;

            if (this.numClicks === 1) {
                this.clickTimer = setTimeout(() => {
                    this.numClicks = 0;
                    this.singleRowClick(item, e);
                }, 200);
            } else if (this.numClicks === 2) {
                clearTimeout(this.clickTimer);
                this.numClicks = 0;
                this.doubleRowClick(item, e);
            }
        },

        addNew() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.EDIT_BUSINESS_UNIT,
                companyId: this.$props.companyId,
                page: this.page,
                itemsPerPage: this.itemsPerPage
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        select(item) {
            console.log("SELECT");
            console.log(item);
            // this.$emit('select-item', item)
            // this.$store.commit('seller/openItem', 0);
        },

        setSortType() {
            this.sort = {...this.sort, type: this.sort.type === 'asc' ? "desc" : "asc"}
        },

        selectSort(item) {
            this.sort = {field: item.key, subField: item.subKey, title: item.name, type: 'asc'}
        },

        updateBusinessUnits(items) {
            this.$store.commit('master/setBusinessUnits', this.sortedItems(items))
        },

        onPageChange(page) {
            this.page = page;
            this.fetchBusinessUnits(this.$props.companyId)
        },

        setOrder(field) {
            const existing_order = !isEmpty(this.sort);
            if (existing_order) {
                // Change type (ASC or DESC)
                this.sort = {
                    ...this.sort,
                    type: this.sort.type === 'asc' ? 'desc' : 'asc'
                }
            } else {
                // Add new order
                this.sort = {
                    field: field,
                    type: 'desc'
                }
            }
        },

        async fetchBusinessUnits(companyId) {
            if (companyId) {
                try {
                    this.fetchingBusinessUnits.loading = true;
                    this.fetchingBusinessUnits.error = null;

                    const payload = {
                        companyId: companyId,
                        page: this.page,
                        itemsPerPage: this.itemsPerPage
                    }
                    await handleFetchBusinessUnits(payload, (response) => {
                        this.page = response.page;
                        this.$store.commit('master/setTotalBusinessUnits', response.totalCount)
                        this.updateBusinessUnits(response.businessUnits);
                    }, () => {
                        this.fetchingBusinessUnits.error = 'Loading company business units failed. Please try again.'
                    })

                    this.fetchingBusinessUnits.loading = false

                } catch (err) {
                    this.fetchingBusinessUnits.error = 'Loading company business units failed. Please try again.'
                    this.fetchingBusinessUnits.loading = false
                }
            } else {
                this.fetchingBusinessUnits.error = 'No company ID provided. '
            }
        },


    },

    components: {
        'autralis-combo-button-comp': comboButton,
        'autralis-base-col-comp': BaseCol,
        'autralis-base-col-country-comp': BaseColCountry,
        'autralis-base-col-check-comp': BaseColCheck,
        'autralis-base-col-state-comp': BaseColState,
        'autralis-base-col-multiple-items-comp': BaseColMultipleItems,
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
        'autralis-paginator-comp': Paginator,
        'autralis-modal-comp': Modal,
        'autralis-delete-business-unit-comp': DeleteBusinessUnit,
        'autralis-loading-table-comp': LoadingTable,
    },

    created() {
        window.addEventListener("resize", this.onResize);
        this.setInitSort();
        this.fetchBusinessUnits(this.$props.companyId);
        this.onResize();
    },

    destroyed() {
        window.removeEventListener("resize", this.onResize);
    },
}
</script>

<style scoped>
.dropdown-nav {
    top: 120%;
    min-width: 150px;
    width: -webkit-max-content;
    width: -moz-max-content;
    width: max-content;
}

.business-units-header-sm, .business-units-header-base, .business-units-header-lg {
    display: none;
}

@media (max-width: 1245px) {
    .business-units-header-sm {
        display: flex;
    }
}

@media (min-width: 1245px) and (max-width: 1450px) {
    .business-units-header-base {
        display: flex;
    }
}

@media (min-width: 1450px) {
    .business-units-header-lg {
        display: flex;
    }
}

</style>