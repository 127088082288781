<template>
    <business-units :companyId="$store.getters['master/company'].id"/>
</template>

<script>

import BusinessUnits from "@/master/views/Home/company/BusinessUnits";

export default {
    name: "BusinessUnitsDashboard",
    components: {
        'business-units': BusinessUnits
    }
}
</script>

<style scoped>
</style>