<template>
    <div class="flex flex-col p-6 w-full">
        <autralis-error-comp :show="error !== ''" :text="error"/>


        <header class="space-y-1 py-2 px-4 bg-blue-700 rounded sticky info-header">
            <!-- First step buttons -->
            <div v-if="step === 0" class="flex w-full">
                <button type="button"
                        @click="handleNext"
                        class="primary-button">
                        <span v-if="loading" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Loading</translate> ...
                        </span>
                    <span v-else>Next</span>
                </button>
            </div>

            <!-- Second step buttons -->
            <div v-if="step === 1" class="flex w-full space-x-2">
                <button type="button"
                        @click="step = 0"
                        class="flex items-center secondary-button whitespace-nowrap">
                    <i class="far fa-arrow-left"/>
                    <span class="hidden md:block ml-2">Back</span>
                </button>
                <button type="button"
                        @click="handleActivate"
                        class="primary-button whitespace-nowrap">
                        <span v-if="loading" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Activating</translate> ...
                        </span>
                    <span v-else>Activate</span>
                </button>
            </div>

            <!-- First step buttons -->
            <div v-if="step === 3" class="flex w-full">
                <button type="button"
                        @click="handleDeactivate"
                        class="secondary-button">
                        <span v-if="loading" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Deactivating</translate> ...
                        </span>
                    <span v-else>Deactivate</span>
                </button>
            </div>
        </header>

        <div class="w-full border-0 md:border border-gray-200 rounded pb-10 md:pt-10 px-0 md:px-6 lg:px-16 xl:px-28 mt-6" style="max-width: 1024px;">
            <autralis-loading-comp v-if="checking"/>
            <div v-else>
                <div class="text-left mb-9">
                    <h2 class="text-2xl md:text-3xl lg:text-4xl font-extrabold tracking-tight text-gray-900">
                        Two factor authentication
                    </h2>
                    <p class="mt-1 text-base md:text-lg leading-6 text-gray-500">
                        Add a new layer of security to your account.
                    </p>
                </div>


                <!-- CONTENT -->
                <div class="flex flex-col flex-1 py-1">

                    <!-- First step -->
                    <div v-if="step === 0" class="flex flex-col flex-1">
                        <div class="flex space-x-2">
                            <img :src="require('@/master/assets/home/google-authenticator.png')" style="height: 50px;" alt="Google authenticator"/>
                            <img :src="require('@/master/assets/home/microsoft-auth-app.png')" style="height: 50px;" alt="Microsoft Auth APP"/>
                        </div>
                        <div class="flex flex-col w-full whitespace-normal text-gray-500 mt-1">
                            <div>For iPhone: Install <span class="font-semibold">Google Authenticator App</span> on your mobile device.</div>
                            <div>For Android or Windows mobile: Install <span class="font-semibold">Microsoft authenticator App</span> on your mobile device.</div>
                        </div>
                    </div>

                    <!-- Second step -->
                    <div v-if="step === 1" class="flex flex-col lg:flex-row flex-1 items-center">
                        <div class="border border-gray-200 rounded-md" style="height: 250px; width: 250px;">
                            <img :src="barcodeUrl" alt="Authenticator barcode" class="h-full w-full"/>
                        </div>

                        <div class="flex flex-col flex-1 ml-0 lg:ml-6 w-full mt-6 lg:mt-0">
                            <autralis-input-group-comp placeholder="Enter the code"
                                                       :error="error"
                                                       @on-change="code = $event"
                                                       :value="code"/>


                            <div class="flex w-full whitespace-normal text-gray-500 mt-1">
                                Add a new entry and scan this QR code and fill in the validation code
                            </div>
                        </div>

                    </div>

                    <!-- Third step -->
                    <div v-if="step === 3" class="flex flex-col flex-1">
                        <div class="flex items-center rounded-md success-box p-4">
                            <i class="fal fa-lock-alt text-green-400"/>
                            <div class="ml-3 text-sm">Authenticator is <span class="font-semibold">active</span>.</div>
                        </div>
                    </div>

                </div>


                <div class="mt-9">
                    <!-- First step buttons -->
                    <div v-if="step === 0" class="flex w-full">
                        <button type="button"
                                @click="handleNext"
                                class="primary-button-dark">
                        <span v-if="loading" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Loading</translate> ...
                        </span>
                            <span v-else>Next</span>
                        </button>
                    </div>

                    <!-- Second step buttons -->
                    <div v-if="step === 1" class="flex w-full space-x-2">
                        <button type="button"
                                @click="step = 0"
                                class="secondary-button">
                            <i class="far fa-arrow-left mr-2"></i> Back
                        </button>
                        <button type="button"
                                @click="handleActivate"
                                class="primary-button-dark">
                        <span v-if="loading" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Activating</translate> ...
                        </span>
                            <span v-else>Activate</span>
                        </button>
                    </div>

                    <!-- First step buttons -->
                    <div v-if="step === 3" class="flex w-full">
                        <button type="button"
                                @click="handleDeactivate"
                                class="secondary-button">
                        <span v-if="loading" class="flex items-center">
                            <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Deactivating</translate> ...
                        </span>
                            <span v-else>Deactivate</span>
                        </button>
                    </div>
                </div>


            </div>
        </div>
    </div>
</template>

<script>

import axios from "axios";
import loading from "../../../../../components/loading";
import InputGroup from "@/components/input-group";
import ErrorBox from "@/components/error-box";

const defaultBarcodeUrl = 'https://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=otpauth%3A%2F%2Ftotp%2Falice%40google.com%3Fsecret%3DJBSWY3DPEHPK3PXP';

export default {
    name: "TwoFactorDashboard",
    data() {
        return {
            step: 0,
            code: '',
            loading: false,
            checking: false,
            barcodeUrl: defaultBarcodeUrl,
            error: ''
        }
    },

    methods: {
        async useFetch(endpoint, onErrorCb, onSuccessCb, data = null, type = 'get') {
            try {
                const queryParams = data && {params: data}
                this.loading = true;
                this.error = '';
                const response = await axios[type](endpoint, queryParams, {withCredentials: true})
                response.data.result === 'Ok' ? onSuccessCb(response.data) : onErrorCb(response.data)
                this.loading = false

            } catch (err) {
                onErrorCb()
                this.loading = false
            }
        },

        async handleStatusCheck() {
            this.checking = true
            await this.useFetch(
                '/manager/expert3/xp3/two-factor-authenticator/0/',
                () => this.error = 'Loading current authenticator settings failed. Please try again.',
                data => {
                    if (data.active)
                        this.step = 3;

                }
            );
            this.checking = false
        },

        async handleNext() {
            await this.useFetch(
                '/manager/expert3/xp3/two-factor-authenticator/1/',
                () => this.error = 'Loading authenticator code failed. Please try again.',
                (data) => {
                    this.barcodeUrl = "https://chart.apis.google.com/chart?cht=qr&chs=200x200&chl=otpauth%3A%2F%2Ftotp%2FManager%40Autralis%3Fsecret%3D" + data.code
                    this.step = 1
                }
            );
        },

        async handleActivate() {
            await this.useFetch(
                '/manager/expert3/xp3/two-factor-authenticator/2/',
                (response) => this.error = response.message,
                () => this.step = 3,
                {code: this.code.replace(/ /g, '')}
            );
        },

        async handleDeactivate() {
            await this.useFetch(
                '/manager/expert3/xp3/two-factor-authenticator/3/',
                () => this.error = 'Deactivating authenticator failed. Please try again.',
                () => this.step = 0
            );
        }
    },

    components: {
        'autralis-error-comp': ErrorBox,
        'autralis-loading-comp': loading,
        'autralis-input-group-comp': InputGroup,
    },

    created() {
        this.handleStatusCheck()
    }
}
</script>

<style scoped>
.info-header {
    top: 0;
    z-index: 10
}

@media (max-width: 1023px) {
    .info-header {
        top: 157px;
    }
}
</style>