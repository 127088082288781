<template>
    <div class="flex flex-1 w-full">
        <div v-if="!activeComponent" class="w-full">
            <div class="flex bg-orange-50 border border-orange-100 p-4 text-sm text-orange-700 m-6 rounded-md">No content to show. Please contact Autralis.</div>
        </div>
        <div v-else class="flex flex-1 w-full ">
            <component :is="activeComponent"/>
        </div>
    </div>
</template>


<script>

import GeneralDashboard from "./company/dashboards/GeneralDashboard";
import UsersDashboard from "./company/dashboards/UsersDashboard";
import InfoDashboard from "./mysettings/dashboards/InfoDashboard";
import PasswordDashboard from "./mysettings/dashboards/PasswordDashboard";
import TwoFactorDashboard from "./mysettings/dashboards/TwoFactorDashboard";
import {find, forEach, head, map} from "lodash";
import {DASHBOARD_KEYS, MENU_KEYS} from "../../constants";
import SupplierUsers from "./suppliers/dashboards/SupplierUsers";
import SupplierAddresses from "@/master/views/Home/suppliers/dashboards/SupplierAddresses";
import AddressesDashboard from "@/master/views/Home/company/dashboards/AddressesDashboard";
import GetStarted from "@/master/views/Home/getstarted/GetStarted";
import Message from "@/master/views/Home/messages/Message";
import NewsContent from "@/master/views/Home/news/NewsContent";
import BusinessUnitsDashboard from "@/master/views/Home/company/dashboards/BusinessUnitsDashboard";
import BusinessUnitGroupsDashboard from "@/master/views/Home/company/dashboards/BusinessUnitGroupsDashboard";


const COMPONENTS = {
    [MENU_KEYS.GET_STARTED]: [
        {name: 'get-started', component: GetStarted, key: DASHBOARD_KEYS.GET_STARTED}
    ],
    [MENU_KEYS.MESSAGES]: [
        {name: 'message', component: Message, key: DASHBOARD_KEYS.MESSAGE}
    ],
    [MENU_KEYS.NEWS]: [
        {name: 'news', component: NewsContent, key: DASHBOARD_KEYS.NEWS_CONTENT}
    ],
    [MENU_KEYS.SUPPLIERS]: [
        {name: 'supplier-addresses', component: SupplierAddresses, key: DASHBOARD_KEYS.SUPPLIERS_ADDRESSES},
        {name: 'supplier-users', component: SupplierUsers, key: DASHBOARD_KEYS.SUPPLIERS_USERS},
    ],
    [MENU_KEYS.COMPANY]: [
        {name: 'company-general', component: GeneralDashboard, key: DASHBOARD_KEYS.COMPANY_GENERAL},
        {name: 'company-addresses', component: AddressesDashboard, key: DASHBOARD_KEYS.COMPANY_ADDRESSES},
        {name: 'company-users', component: UsersDashboard, key: DASHBOARD_KEYS.COMPANY_USERS},
        {name: 'company-business-unit-groups', component: BusinessUnitGroupsDashboard, key: DASHBOARD_KEYS.COMPANY_BUSINESS_UNIT_GROUPS},
        {name: 'company-business-units', component: BusinessUnitsDashboard, key: DASHBOARD_KEYS.COMPANY_BUSINESS_UNITS},
    ],
    [MENU_KEYS.SETTINGS]: [
        {name: 'settings-info', component: InfoDashboard, key: DASHBOARD_KEYS.SETTINGS_INFO},
        {name: 'settings-password', component: PasswordDashboard, key: DASHBOARD_KEYS.SETTINGS_PASSWORD},
        {name: 'settings-two-factor', component: TwoFactorDashboard, key: DASHBOARD_KEYS.SETTINGS_2_FACTOR},
    ]
}

let MAPPED_COMPONENTS = [];
forEach(Object.keys(COMPONENTS), key => {
    MAPPED_COMPONENTS = [...MAPPED_COMPONENTS, ...COMPONENTS[key]]
})

export default {
    name: 'Main',
    data() {
        return {
            components: [],
            activeComponent: null
        }
    },

    watch: {
        '$store.state.master.dashboard': function () {
            this.setActiveComponent()
        }
    },

    methods: {
        setActiveComponent() {
            const totalComponents = this.components.length
            let component = totalComponents === 1 ?
                head(this.components) :
                find(this.components, c => c.key === this.$store.getters['master/dashboard']);

            if (component) {
                this.activeComponent = component.component
            } else {
                this.activeComponent = null
            }
        },

        initializeComponents() {
            let components = [];
            map(Object.keys(COMPONENTS), menuKey => {
                if (menuKey === this.$store.getters['master/application'].subMenu.methodName) {
                    components = COMPONENTS[menuKey]
                }
            })
            this.components = components;
        }
    },

    components: Object.assign({}, ...(MAPPED_COMPONENTS.map(item => ({[item.name]: item.component})))),

    created() {
        this.initializeComponents();
        this.setActiveComponent();
    }
}
</script>
