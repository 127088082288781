<template>
    <div class="flex flex-1 w-full h-full">
        <autralis-success-comp :show="deleted" :text="successDeleteLabel"/>
        <autralis-error-comp :show="deleteError !== null" :text="deleteError"/>

        <div v-if="!selectedMessage" class="flex flex-1 w-full h-full items-center justify-center">
            <div class="text-center">
                <i class="fal fa-envelope text-gray-600 text-5xl"/>
                <p class="mt-1 text-sm text-gray-500">Click on the message to see the content.</p>
            </div>
        </div>
        <div v-else class="flex flex-1 w-full h-full">
            <div v-if="loading" class="flex w-full items-center my-6">
                <autralis-loading-comp/>
            </div>

            <div v-else-if="!loading && loadingError" class="flex flex-1 w-full h-full items-center justify-center ">
                <div class="text-center">
                    <i class="fal fa-exclamation-circle text-red-600 text-5xl"/>
                    <p class="mt-1 text-sm text-red-500">{{ loadingError }}</p>
                </div>
            </div>
            <div v-else class="flex flex-col w-full">
                <div class="lg:flex lg:items-center lg:justify-between p-6 bg-gray-50 w-full border-t border-gray-100 sticky top-0">
                    <div class="flex-1 min-w-0">
                        <div class="flex items-center">
                            <h2 class="flex flex-1 truncate text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                                {{ message.subject }}
                            </h2>

                            <!--  On sm screens (<640px)-->
                            <div class="block sm:hidden">
                                <button type="button"
                                        v-if="isInbox"
                                        @click="deleteMessage(message.id)"
                                        class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">

                                    <span v-if="deleting" class="flex items-center">
                                        <span class="fas fa-spinner-third fa-spin"></span>
                                    </span>
                                    <span v-else><i class="fal fa-trash"/> </span>

                                </button>
                                <button v-if="isDeletedInbox"
                                        type="button"
                                        @click="undeleteMessage(message.id)"
                                        class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                    <span v-if="deleting" class="flex items-center">
                                        <span class="fas fa-spinner-third fa-spin"></span>
                                    </span>
                                    <span v-else><i class="fal fa-trash"/></span>

                                </button>
                            </div>


                        </div>
                        <div class="mt-1 flex flex-col sm:flex-row sm:flex-wrap sm:mt-0">
                            <div class="mt-2 flex items-center text-sm text-gray-500 mr-6">
                                <i class="fas fa-inbox-out mr-1 text-xs"/> From: <span class="font-semibold ml-2">{{ message.from }}</span>
                            </div>
                            <div class="mt-2 flex items-center text-sm text-gray-500 mr-6">
                                <i class="fas fa-inbox-in mr-1 text-xs"/> For: <span class="font-semibold ml-2">{{ message.for_who }}</span>
                            </div>
                            <div class="mt-2 flex items-center text-sm text-gray-500">
                                <i class="fas fa-calendar mr-1 text-xs"/> Time: <span class="font-semibold ml-2">{{ formatDate(message.sent) }}</span>
                            </div>
                        </div>
                    </div>
                    <!--  On bigger screens (>=640px)-->
                    <div class="hidden sm:block mt-5 flex lg:mt-0 lg:ml-4">
                        <button type="button"
                                v-if="isInbox"
                                @click="deleteMessage(message.id)"
                                class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">

                            <span v-if="deleting" class="flex items-center">
                                <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Deleting</translate> ...
                            </span>
                            <span v-else>
                                <i class="fal fa-trash mr-2"/> <translate>Delete</translate>
                            </span>

                        </button>
                        <button v-if="isDeletedInbox"
                                type="button"
                                @click="undeleteMessage(message.id)"
                                class="inline-flex items-center px-4 py-2 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                            <span v-if="deleting" class="flex items-center">
                                <span class="fas fa-spinner-third fa-spin mr-2"></span> <translate>Undeleting</translate> ...
                            </span>
                            <span v-else>
                                <i class="fal fa-trash mr-2"/><translate>Undelete</translate>
                            </span>

                        </button>
                    </div>
                </div>
                <div class="p-6">
                    {{ message.content }}
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import {handleDeleteMessage, handleFetchMessage, handleUndeleteMessage} from "@/master/views/Home/messages/handlers";
import Loading from "@/components/loading";
import moment from "moment";
import {filter, head} from "lodash";
import {INBOX_KEYS} from "@/master/constants";
import SuccessBox from "@/components/success-box";
import ErrorBox from "@/components/error-box";

export default {
    name: "Message",
    data() {
        return {
            loading: false,
            loadingError: null,
            message: null,
            selectedMessage: null,
            deleting: false,
            deleteError: null,
            deleted: null
        }
    },

    watch: {
        '$store.state.master.selectedMessage': function (newVal) {
            this.selectedMessage = newVal;
            if (newVal) {
                this.fetchMessage(newVal)
            }
        }
    },

    computed: {
        inboxType() {
            return this.$store.getters['master/selectedMessagesInboxType'];
        },

        isInbox() {
            return this.$store.getters['master/selectedMessagesInboxType'] === INBOX_KEYS.INBOX;
        },

        isDeletedInbox() {
            return this.$store.getters['master/selectedMessagesInboxType'] === INBOX_KEYS.DELETED;
        },
        successDeleteLabel() {
            return this.isInbox ? "The message deleted successfully." : 'The message undeleted successfully.'
        }
    },

    methods: {
        initializeMessage() {
            if (this.$store.getters['master/selectedMessage']) {
                this.selectedMessage = this.$store.getters['master/selectedMessage'];
                this.fetchMessage(this.$store.getters['master/selectedMessage'])
            }
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY, HH:mm') + ' (' + moment(date, "YYYYMMDD").fromNow() + ')';
        },

        selectTheLastOne() {
            const msg = head(this.$store.getters['master/messages'])
            if (msg) {
                this.$store.commit('master/setSelectedMessage', msg.id);
            }
        },

        async deleteMessage(messageId) {
            try {
                this.deleting = true;
                this.deleted = false;
                this.deleteError = null

                const payload = {
                    messageId: messageId
                };

                await handleDeleteMessage(payload, () => {
                    // Update message delete flag in the store
                    const updatedMessages = filter(this.$store.getters['master/messages'], m => m.id !== messageId);
                    this.$store.commit('master/setMessages', updatedMessages);
                    // Select it if there is only one left
                    if (this.$store.getters['master/messages'].length === 1) {
                        this.selectTheLastOne();
                    } else {
                        // Unselect message if there is more than one
                        this.$store.commit('master/setSelectedMessage', null);
                    }
                    // Set message in the local state
                    this.message = null;
                }, (error) => {
                    this.deleteError = error
                })

                this.deleting = false;
                this.deleted = true;

            } catch (err) {
                this.deleteError = 'The message could not be deleted. Please try again.'
                this.deleting = false;
                this.deleted = false;
            }
        },

        async undeleteMessage(messageId) {
            try {
                this.deleting = true;
                this.deleted = false;
                this.deleteError = null

                const payload = {
                    messageId: messageId
                };

                await handleUndeleteMessage(payload, () => {
                    // Update message delete flag in the store
                    const updatedMessages = filter(this.$store.getters['master/messages'], m => m.id !== messageId);
                    this.$store.commit('master/setMessages', updatedMessages);
                    // Select it if there is only one left
                    if (this.$store.getters['master/messages'].length === 1) {
                        this.selectTheLastOne();
                    } else {
                        // Unselect message if there is more than one
                        this.$store.commit('master/setSelectedMessage', null);
                    }
                    // Set message in the local state
                    this.message = null;
                }, (error) => {
                    this.deleteError = error
                })

                this.deleting = false;
                this.deleted = true;

            } catch (err) {
                this.deleteError = 'The message could not be deleted. Please try again.'
                this.deleting = false;
                this.deleted = false;
            }
        },

        async fetchMessage(messageId) {
            try {
                this.loading = true;
                this.loadingError = null

                const payload = {
                    messageId: messageId
                };

                await handleFetchMessage(payload, ({message, totalNewMessages}) => {
                    // Update message read flag in the store
                    const updatedMessages = [...filter(this.$store.getters['master/messages'], m => m.id !== messageId), message];
                    this.$store.commit('master/setMessages', updatedMessages);
                    // Update total new messages in the store
                    this.$store.commit('master/setTotalNewMessages', parseInt(totalNewMessages));

                    // Set message in the local state
                    this.message = message;
                }, (error) => {
                    this.loadingError = error
                })

                this.loading = false;

            } catch (err) {
                this.loadingError = 'The message could not be fetched. Please try again.'
                this.loading = false;
            }
        }
    },

    created() {
        this.initializeMessage();
    },

    components: {
        'autralis-loading-comp': Loading,
        'autralis-success-comp': SuccessBox,
        'autralis-error-comp': ErrorBox,
    }
}
</script>

<style scoped>

</style>